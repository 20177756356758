$avenirBlack: 'AvenirBlack', cursive;
$avenirHeavy: 'AvenirHeavy', serif;
$avenirBook: 'AvenirBook', sans-serif;

// COLORS

//main colors
$primary-color: #ef0482;
$primary-color-decolored: rgba($primary-color, 0.7);
$secondary-color: #ffffff;
$secondary-color-decolored: #c8c8c8;
$disabled: #838190;

// backgrounds
$background-1: #201d2b;
$background-2: #232736;
$background-3: #333946;
$background-4: #313444;
$background-5: #252736;
$background-6: #5a5961;
$background-7: #242736;

//common colors
$grey-dark: #464545;
$grey-title: #7e8295;
$grey-input: #515566;
$grey-footer: #9a99a0;
$grey-cookies: #666464;
$grey: #646d76;
$grey2: #7f8295;
$grey-decolored: #afb2c2;
$grey-light: #f0f0f0;
$white: #ffffff;
$black: #000;
$danger: #a7ae41;
$danger-decolored: rgba($danger, 0.5);

// SETTINGS
$border-radius-element: 8px;
$border-radius-wrapper: 22px;

// https://getcssscan.com/css-box-shadow-examples
$box-shadow-element: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
$box-shadow-wrapper: rgba(108, 104, 108, 0.5) 0px 8px 24px;
