* {
  box-sizing: border-box;
  transition: 0.1s ease;
}

form,
ul,
li,
button,
a {
  color: $secondary-color;
  padding: 0;
  margin: 0;
  list-style-type: none;
  background-color: transparent;
  border: none;
}

ol {
  padding: 0;
}

form {
  width: 100%;
}

button,
a {
  cursor: pointer;
}

a {
  color: $primary-color;
}

.center {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.center-text {
  text-align: center;
}

.content-on-the-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  flex-direction: column;

  button {
    margin-right: 0;
  }

  & > button {
    margin-bottom: 16px;
  }

  @include media(desktop) {
    flex-direction: row;

    button {
      margin-left: 22px;
      margin-bottom: 0;
    }
  }

  &.allways {
    flex-direction: row;

    button {
      margin-left: 12px;
      margin-bottom: 0;
    }
  }
}

.content-on-the-left {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 100%;

  button,
  .text {
    margin-left: 12px;
  }

  .section-title {
    width: fit-content;
  }

  @include media(desktop) {
    flex-direction: row;
  }
}

.content-left-and-right {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  column-gap: 12px;

  & > button {
    margin-bottom: 16px;
  }

  .content {
    margin-bottom: 24px;
  }

  &.reverse {
    flex-direction: column-reverse;
  }

  &.center {
    justify-content: center;
    align-items: center;
  }

  @include media(desktop) {
    flex-direction: row;

    button {
      margin-bottom: 0;
    }

    &.reverse {
      flex-direction: row;
    }

    &.center {
      justify-content: space-between;
    }
  }

  .content-on-the-right {
    width: fit-content;
  }
}

.buttons-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  @include media(desktop) {
    flex-direction: row;
  }

  button,
  .multi-select {
    margin-right: 0;
    margin-bottom: 16px;

    @include media(desktop) {
      margin-right: 16px;
    }

    svg {
      margin-right: 8px;
    }
  }
}

.absolute-right {
  position: relative;

  .absolute-right-element {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.card-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 22px;

  @include media(desktop) {
    grid-template-columns: repeat(5, 1fr);
  }

  .card-item {
    img {
      height: 160px;
    }

    button,
    .recipe-card {
      height: 100%;
      width: 100%;
    }
  }

  &.workouts {
    @include media(desktop) {
      grid-template-columns: repeat(3, 1fr);
    }

    .card-item {
      grid-column: span 1;

      &:last-of-type {
        grid-column: span 1;
      }
    }
  }
}

.page-wrapper {
  background-color: $background-1;
  width: 100%;
  flex-grow: 1;
  max-width: 1350px;
  padding: 0 12px 32px;

  @include media(desktop) {
    padding: 0 24px 32px;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content-wrapper {
    background-color: $background-2;
    margin: 32px auto;
    padding: 32px 20px;
    border-radius: $border-radius-wrapper;
    max-width: 590px;

    @include media(desktop) {
      margin: 32px auto;
      padding: 32px;
    }
  }
}

.background-2,
.background-3 {
  background-color: $background-2;
  padding: 16px;
  border-radius: $border-radius-wrapper;
  margin: 0 16px;

  &.no-margin {
    margin: 0;
  }
}

.background-3 {
  background-color: $background-3;
}

.half-page-width {
  max-width: 500px;
}

.texted-logo {
  text-decoration: none;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.message-box {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;

  @include media(desktop) {
    margin: 0;
  }

  .message-box-content {
    padding: 32px 24px;
    background-color: $background-3;
    border-radius: $border-radius-wrapper;
    text-align: center;

    .isSubtitle {
      font-size: 20px;
    }

    .days-remaining {
      font-size: 18px;
    }
  }
}

.align-right {
  margin-left: auto;
  width: 100%;
  justify-content: flex-end;
}
