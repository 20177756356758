.button {
  padding: 16px;
  color: $secondary-color;
  background-color: transparent;
  border: none;
  border-radius: $border-radius-element;
  cursor: pointer;
  vertical-align: middle;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: $box-shadow-element;
  font-family: $avenirBook;

  @include media(desktop) {
    padding: 12px;
  }

  &:hover {
    box-shadow: none;
  }

  &.big {
    padding: 16px 22px;

    .text {
      letter-spacing: 1px;
      font-size: 16px;
    }
  }

  &.center {
    margin: 0 auto;
    width: fit-content;
  }

  p {
    line-height: 16px;
  }

  a {
    color: $secondary-color;
  }

  svg {
    font-size: 14px;
    margin: 0 6px 0 0;

    &.right {
      margin: 0 0 0 6px;
    }
  }

  &.primary {
    background-color: $primary-color;

    &:hover {
      background-color: $primary-color-decolored;
    }
  }

  &.secondary {
    border: thin solid $primary-color;

    &:disabled {
      border: thin solid $disabled;
    }

    &:hover {
      background-color: $primary-color-decolored;
    }
  }

  &.third {
    background-color: $background-4;
  }

  &.active {
    background-color: $primary-color-decolored;

    &:hover {
      background-color: $primary-color;
    }
  }

  &:disabled {
    background-color: $background-3;
    cursor: not-allowed;
    color: $disabled;

    p {
      color: inherit;
    }

    &:hover {
      background-color: transparent;
    }
  }

  &.only-icon {
    padding: 8px;
    width: fit-content;

    svg {
      font-size: 18px;
      padding: 0;
      margin: 0;
    }
  }

  &.small {
    padding: 8px;
  }

  &.background-2 {
    background-color: $background-2;
  }
}

button.link,
.link {
  color: $primary-color;
  text-decoration: underline;
  transition: 0.2s;
  padding-left: 0;
  padding-right: 0;
  box-shadow: none;

  .text {
    color: $primary-color;
    font-size: 12px;
  }

  &:hover {
    color: $primary-color;

    p,
    svg {
      color: $primary-color;
    }
  }
}

.login-cta {
  font-size: 16px;
}
