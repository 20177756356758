@import '../../styles/shared.scss';

p,
h1,
h2,
h3 {
  margin: 0;
  transition: 0.3s;
}

.title,
.subtitle,
h3 {
  font-family: $avenirBlack;
  font-size: 26px;
  color: $secondary-color;

  &.primary,
  span.primary {
    color: $primary-color;
  }

  .secondary,
  span.secondary {
    color: $secondary-color;
  }

  &.center {
    text-align: center;
  }
}

.subtitle {
  font-size: 18px;
  font-family: $avenirHeavy;

  span {
    font-size: 18px;
  }
}

.section-title {
  width: 100%;

  &__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__text {
    font-size: 22px;
    font-weight: 700;
  }

  button {
    padding: 0;
    margin-left: 12px;

    svg {
      color: $secondary-color;

      &:hover {
        color: $primary-color;
      }
    }
  }

  &.center {
    text-align: center;
  }
}

.text {
  font-family: $avenirBook;
  font-size: 14px;
  color: $secondary-color;

  &.bold,
  .bold {
    font-family: $avenirBlack;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.cta {
    text-transform: uppercase;
    font-family: $avenirHeavy;
    font-size: 14px;

    @media screen and (min-width: 1024px) {
      font-size: 12px;
    }
  }

  &.decolored {
    color: $secondary-color-decolored;
  }

  &.danger {
    color: $danger;
    font-size: 12px;
  }

  &.error {
    color: $primary-color;
    font-size: 12px;
  }

  &.disabled {
    color: $disabled;
  }

  &.small {
    font-size: 12px;
  }

  &.align-right {
    text-align: right;
  }

  &.center {
    text-align: center;
  }

  &.primary,
  span.primary {
    color: $primary-color;
  }

  &.line {
    height: 1px;
    width: 100%;
    background-color: $grey-footer;
  }

  &.font-32 {
    font-size: 32px;
  }

  &.font-18 {
    font-size: 18px;
  }

  &.font-16 {
    font-size: 16px;
  }

  &.font-14 {
    font-size: 14px;
  }
}

.font-18 {
  font-size: 18px;
}

.black {
  color: $black;
}
