.modal {
  position: absolute;
  background-color: rgba($color: $background-1, $alpha: 0.8);
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  .multi-select {
    z-index: 1;
  }

  .modal-content {
    width: 95%;
    max-height: 90vh;
    padding: 24px 16px;
    background-color: $background-2;
    border-radius: $border-radius-wrapper;
    position: relative;
    z-index: 4;
    box-shadow: $box-shadow-wrapper;

    @include media(desktop) {
      padding: 42px 32px 32px;
      width: 100%;
    }

    &.scroll {
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .close-icon {
    position: fixed;
    right: 24px;
    top: 24px;
    z-index: 10;

    svg {
      height: 24px;
      width: 24px;
      color: $grey-decolored;
    }

    &:hover {
      svg {
        color: $secondary-color;
      }
    }

    @include media(desktop) {
      position: absolute;
      top: 24px;
      right: 32px;

      svg {
        height: 24px;
        width: 24px;
        color: $disabled;
      }
    }
  }

  &.secondary-modal {
    background-color: rgba($color: $background-1, $alpha: 0.8);

    .modal-content {
      max-width: 590px;
      background-color: $background-2;
    }
  }

  &.full-width {
    .modal-content {
      width: 100%;
      max-height: 100vh;
      height: 100%;
      border-radius: 0;
    }
  }

  @include media(desktop) {
    .modal-content {
      width: 70%;
    }
  }

  &.user-menu-modal {
    .modal-content {
      width: 100%;
      max-height: 100vh;
      height: 100%;
      border-radius: 0;
    }

    .close-icon {
      display: none;
    }

    @include media(desktop) {
      height: fit-content;
      width: 200px;
      top: 0;
      right: 0;
      left: unset;

      .modal-content {
        height: fit-content;
        width: 200px;
      }
    }
  }
}
