.config-table {
  background-color: $background-4;

  tbody {
    tr {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 12px;

      &:hover {
        background-color: $background-2;
        border-radius: $border-radius-wrapper;
      }
    }

    td {
      padding: 8px;
      font-size: 16px;

      &.only-icon {
        display: flex;
        width: fit-content;
      }

      &:first-of-type {
        width: 50%;
      }

      .toggle {
        display: flex;
      }
    }
  }
}

.config-section {
  background-color: $background-5;
  padding: 24px;
  border-radius: $border-radius-wrapper;

  table {
    background-color: $background-5;

    tr {
      &:hover {
        background: $background-4;
      }
    }
  }

  @include media(desktop) {
    .content-left-and-right {
      align-items: flex-end;
      button {
        margin-left: 0;
      }

      input {
        margin-left: 24px;
      }
    }
  }

  .section-title {
    width: fit-content;
  }

  .helper-and-button {
    display: flex;

    .text {
      margin-right: 8px;
    }
  }

  form {
    .subtitle {
      font-size: 18px;
      font-family: $avenirBlack;
    }
  }
}
