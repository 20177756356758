@import '../../styles/common/mixins.scss';
@import '../../styles/shared.scss';

body {
  color: $secondary-color;
  font-family: $avenirBook;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  #root {
    width: 100%;

    .app-container {
      background-color: $background-1;
      width: 100%;
      min-height: 100vh;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
    }
  }
}

.app-container {
  .app-wrapper {
    flex-grow: 1;
    padding: 24px 12px 24px;
    box-sizing: border-box;
    min-height: 100%;
    width: 100%;
    max-width: 1350px;
    display: grid;
    gap: 18px 0px;
    grid-template-columns: 1fr 2fr 2fr;
    grid-template-rows: 32px 1fr;
    grid-template-areas:
      'HEADER HEADER HEADER'
      'MAIN MAIN MAIN';
    // 'FOOTER FOOTER FOOTER';

    @include media(desktop) {
      gap: 24px;
      grid-template-columns: 1fr 2fr 2fr;
      grid-template-rows: 32px 1fr;
      grid-template-areas:
        'HEADER HEADER HEADER'
        'NAV MAIN MAIN';
      // 'FOOTER FOOTER FOOTER';
    }

    header {
      grid-area: HEADER;
      display: grid;
      gap: 0px 0px;
      grid-template-rows: 1fr;
      grid-template-columns: 3fr 1fr 32px;
      grid-template-areas: 'LOGO USER BREADCRUMB';
      align-items: center;
      background-color: $background-1;
      height: fit-content;

      @include media(desktop) {
        gap: 24px;
        grid-template-columns: 1fr 2fr 2fr;
        grid-template-areas: 'LOGO RRSS USER';
      }

      .texted-logo {
        grid-area: LOGO;
        font-size: 22px;
        text-decoration: none;

        @include media(desktop) {
          font-size: 26px;
        }
      }

      .rrss {
        grid-area: RRSS;
      }

      .user-menu {
        grid-area: USER;
      }

      .breadcrumb {
        grid-area: BREADCRUMB;
        padding: 0;
        box-shadow: none;
        justify-self: end;

        img {
          height: 24px;
        }

        @include media(desktop) {
          display: none;
        }
      }
    }

    .rrss {
      display: none;

      @include media(desktop) {
        display: flex;
      }

      .text {
        margin-right: 24px;

        svg {
          margin-right: 8px;
        }
      }
    }

    .user-menu {
      justify-self: flex-end;
    }

    nav {
      grid-area: NAV;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    main {
      grid-area: MAIN;
      margin: 18px 0;
    }
  }
}

.plain-layout {
  background-color: $white;
  width: 100%;
  flex-grow: 1;

  header {
    padding: 36px;
    background-color: $background-1;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;

    a {
      text-decoration: none;
    }
  }

  main {
    padding: 0 24px;

    @include media(desktop) {
      padding: 0 40px;
    }

    .edit-button {
      margin-top: 32px;
    }

    .title {
      font-size: 36px;
      color: $primary-color;
      text-align: center;
      text-transform: uppercase;
      padding: 32px 0 0;

      @include media(desktop) {
        padding: 64px 32px 0;
      }
    }
  }
}

@media (min-width: 640px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 1280px) {
}
@media screen and (min-width: 1536px) {
}
