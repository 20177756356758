.cookie-wrapper {
  opacity: 0.45;
  background: #1f1d2b;
  width: 100%;
  position: absolute;
  top: 0;
  height: 100vh;
  z-index: 1;
}

.cookies {
  z-index: 2;
  position: fixed;
  bottom: 32px;
  background-color: $white;
  width: 90%;
  border-radius: 5px;
  background: #f7f7f7;
  box-shadow: 2px 2px 24px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  column-gap: 12px;

  @include media(desktop) {
    padding: 20px;
    flex-direction: row;
    row-gap: 16px;
    width: 70%;
  }

  img {
    height: 60px;
    margin-right: 8px;
    margin-bottom: 10px;

    @include media(desktop) {
      margin-bottom: 0;
    }
  }

  .cookies-text {
    color: $grey-cookies;
    margin-right: 12px;
    line-height: 160%;
    font-size: 20px;
  }

  .more-info-link {
    color: $primary-color;
    text-decoration: underline;
  }

  button {
    margin-top: 32px;
    height: fit-content;
    width: 100%;

    @include media(desktop) {
      width: fit-content;
      margin-top: 0;
    }

    &.secondary {
      .text {
        color: $primary-color;
      }
    }
  }
}
