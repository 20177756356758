.rrss {
  a {
    text-decoration: none;
  }

  .text {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    height: 16px;
    margin-right: 8px;
  }
}

.user-menu {
  position: relative;

  .menu-button {
    box-shadow: none;
    padding: 0 12px;

    .text {
      text-transform: capitalize;

      @include media(mobile) {
        display: none;
      }
    }
  }

  .user-menu-links {
    z-index: 5;
    position: absolute;
    top: 32px;
    right: 0;
    width: 220px;
    background-color: $background-4;
    border-radius: $border-radius-wrapper;
    padding: 22px 26px 16px;
    box-shadow: $box-shadow-wrapper;

    .user-name {
      margin-bottom: 24px;
      text-transform: capitalize;

      @include media(desktop) {
        display: none;
      }
    }

    li {
      margin-bottom: 12px;
      font-family: $avenirBlack;

      a {
        width: 100%;
        display: block;
        cursor: pointer;
        color: $secondary-color;
        text-decoration: none;
        font-size: 16px;

        &:hover {
          color: $secondary-color-decolored;
        }

        &::first-letter {
          text-transform: uppercase;
        }
      }

      button {
        margin-top: 20px;
        width: 100%;
      }
    }
  }
}
