.languages {
  display: flex;

  .inactive {
    opacity: 0.4;
  }

  .language:hover {
    opacity: 1;
  }

  img {
    height: 16px;
    margin-right: 16px;
    font-size: 22px;
  }

  button {
    &:last-of-type {
      img {
        margin-right: 0;
      }
    }
  }
}
