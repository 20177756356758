.recipe-card-cta {
  width: 100%;

  .recipe-card {
    background-color: $background-2;
    border-radius: $border-radius-element;
    text-align: left;
    overflow: hidden;

    .recipe-card-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .recipe-card-details-top {
        margin-bottom: 12px;
      }
    }

    .meal {
      font-size: 14px;
      color: $grey-decolored;
      text-align: left;
      margin: 0 14px 0;
    }

    .title {
      font-size: 16px;
      line-height: 18px;
      height: 36px;
      margin: 0 14px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      font-family: $avenirBlack;
    }

    .recipe-card-data {
      margin: 0 14px 14px;
      color: $primary-color;
      font-size: 16px;

      span {
        color: $white;
      }

      .decolored {
        color: $disabled;
      }
    }
  }
}

.recipe-form-container {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 32px;

  .grid-container.ingredients {
    grid-template-columns: 1fr 72px 42px;
  }
}

.recipe-form {
  color: $secondary-color;

  .section {
    background-color: $background-1;
    padding: 12px 22px;
    border-radius: $border-radius-wrapper;
  }

  .measure {
    display: flex;
    align-items: center;

    input {
      height: 46px;
      padding: 12px;
    }
    .text {
      padding-left: 8px;
    }
  }

  .no-submit.primary {
    background-color: transparent;
    width: fit-content;
    padding: 0;
    justify-self: end;
    align-self: center;
    width: 42px;
    height: 42px;

    &:hover {
      background-color: $primary-color-decolored;
    }

    &:disabled {
      background-color: transparent;
    }
  }
}

.recipe-form-details {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  background-color: $background-1;
  padding: 24px 32px;
  border-radius: $border-radius-wrapper;
  margin: 50px auto;

  .title {
    color: $secondary-color;
  }

  .food-image {
    border-radius: $border-radius-element;
  }

  .steps {
    .step {
      display: flex;
      margin-bottom: 8px;

      .text {
        margin-right: 8px;
      }
    }
  }

  .meals {
    display: flex;
    justify-content: space-between;
    padding: 12px 32px;

    .meal {
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        height: 18px;
      }

      .text {
        display: none;
      }

      &.active {
        svg {
          color: $primary-color;
        }
      }
    }
  }
}

.food-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 245px;
  width: 100%;
  background-color: $white;

  @include media(desktop) {
    height: 194px;
  }
}

.image-loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
}

.diet-tab {
  .energy-details {
    @include media(mobile) {
      width: 100%;
      margin: 32px 0 12px;
    }
  }
  .recipes-list {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-template-rows: auto;
    gap: 18px;
    justify-content: center;

    @include media(desktop) {
      justify-content: flex-start;
    }
  }
}

.shooping-list-cta {
  box-shadow: none;
  border-bottom: thin dashed $white;
  padding: 12px 0 2px;
  height: fit-content;
  width: fit-content;
  border-radius: 0;
  font-size: 16px;
  font-family: $avenirBook;
  color: $white;
  text-decoration: none;
  margin-bottom: 8px;

  &::first-letter {
    text-transform: uppercase;
  }

  @include media(mobile) {
    align-self: center;
  }

  .text {
    font-size: 16px;
    font-family: $avenirBook;
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.energy-details {
  display: flex;
  justify-content: space-between;
  width: fit-content;
  background-color: $background-2;
  border-radius: $border-radius-element;
  margin: 24px 24px 0;
  padding: 12px 0;

  &.full-width {
    width: 100%;
    margin: 24px 0;
  }

  @include media(desktop) {
    padding: 12px 22px 12px;
    margin-top: 0;
    justify-content: flex-start;
    justify-content: space-between;
  }

  .propiedad {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 12px;

    .text {
      margin: 0 8px;
    }
  }
}
