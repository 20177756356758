.register-steps {
  display: flex;
  justify-content: center;
  align-items: center;

  .single-step {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    margin: 0 12px;

    .button {
      border-radius: 100%;
      height: 44px;
      width: 44px;
      margin-bottom: 12px;
      background-color: $background-3;

      .text {
        font-size: 18px;
      }
    }

    .step-label {
      font-size: 16px;
      text-align: center;
      font-family: $avenirBlack;
    }

    &.active {
      .button {
        background-color: $primary-color;
      }

      .step-label {
        color: $primary-color;
      }
    }

    &.disabled {
      &.active {
        .button {
          background-color: $primary-color;
          color: $white;
        }

        .step-label {
          color: $primary-color;
        }
      }
    }
  }
}

.register-success {
  text-align: center;

  img {
    height: 120px;
  }

  a {
    text-decoration: none;
  }
}

.personal-info-form {
  .field.checkbox {
    label {
      font-size: 16px;
      font-family: $avenirBook;
    }

    &.checked label {
      text-decoration: none;
    }
  }
}

.gift-selector {
  .buttons {
    background-color: $background-3;
    border-radius: $border-radius-wrapper;
    position: relative;
    height: 32px;

    &::after {
      content: '';
      transition: left 0.27s ease-out;
      position: absolute;
      width: 50%;
      top: 0;
      left: 0;
      height: 100%;
      background-color: $primary-color;
      border-radius: $border-radius-wrapper;
      z-index: 3;
    }

    &.without-gift {
      &::after {
        left: 50%;
      }
    }

    button {
      z-index: 4;
      position: absolute;
      background-color: transparent;
      height: 32px;
      width: 50%;
      font-size: 14px;

      &:last-of-type {
        right: 0;
      }
    }
  }

  .gifts-wrapper {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    height: 200px;

    &::-webkit-scrollbar {
      height: 6px;
      background-color: $background-2;
      border-radius: $border-radius-element;
    }

    &::-webkit-scrollbar-thumb {
      background: $background-3;
      border-radius: $border-radius-element;
    }

    .gift-item {
      cursor: pointer;
      background-color: $white;
      margin: 0 4px;
      padding: 1rem;
      border-radius: 1rem;
      height: 168px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      min-width: 150px;
      position: relative;

      &:after {
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        border-radius: 1rem;
        height: 168px;
        top: 0;
        background-color: rgba($color: #dedfe1, $alpha: 0.4);
      }

      &:hover {
        background-color: $white;

        &:after {
          background-color: transparent;
        }
      }

      &.selected {
        background-color: $white;

        &::after {
          content: '✓';
          position: absolute;
          height: 22px;
          width: 22px;
          background-color: $primary-color;
          top: 16px;
          right: 16px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .image {
        height: 100%;
        width: 100%;
        background-size: cover;
        z-index: 0;
      }

      .text {
        z-index: 1;
        display: block;
        text-align: center;
        width: 100%;
        padding: 0.25rem;
        color: #1c1a1b;
        font-size: 12px;
        text-transform: lowercase;
        height: 44px;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }

  @include media(desktop) {
  }
}
