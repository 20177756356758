.recipe-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .recipe-details-content {
    flex: 1;
    width: 100%;

    &:last-of-type {
      padding-left: 24px;
    }
  }

  .food-image {
    width: 100%;
    border-radius: $border-radius-element;
    height: 276px;

    @include media(desktop) {
      height: 368px;
    }
  }

  .minutes {
    display: flex;
    align-items: center;

    svg {
      margin-right: 4px;
    }
  }

  .steps-title .section-title__text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    span,
    svg {
      margin-right: 4px;
    }
  }

  .steps {
    .step {
      display: flex;
      align-items: flex-start;
      margin-bottom: 8px;

      .text {
        margin-right: 8px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    .recipe-details-content {
      margin: 0;
      margin-bottom: 24px;

      // &:last-of-type {
      //   padding: 0;
      // }
    }
  }
}

.recipe-modal {
  .modal-content {
    width: 90%;
  }
}
