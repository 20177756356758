.progress-graphs {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  gap: 42px;

  @include media(desktop) {
    grid-template-columns: 45% 45%;
  }
}

.body-parameter {
  width: 100%;

  .background-2 {
    padding: 24px;
  }

  .subtitle {
    font-size: 24px;
    width: fit-content;
  }

  .measures {
    display: flex;
    justify-content: space-between;
  }

  .chart {
    height: 200px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;

    .quantity {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .text {
        color: $disabled;
        text-align: right;
      }
    }

    .progress-column {
      height: 100%;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      .column {
        width: 10px;
        background-color: $primary-color;
        border-radius: 5px;
        margin-bottom: 14px;

        &.empty {
          background-color: $background-3;
          height: 100%;
        }
      }

      .text {
        color: $disabled;
      }
    }
  }
}

.instructions {
  ul {
    li {
      margin-bottom: 22px;
    }
  }
}
