.more-links {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(1, 100%);
  grid-template-rows: auto;
  gap: 18px;

  @include media(desktop) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
  }

  li {
    background-color: $background-2;
    height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius-element;

    a,
    button {
      width: 100%;
      height: 100%;
      padding: 20px 0;
      text-decoration: none;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;

      .nav-icon {
        margin-bottom: 8px;

        img {
          height: 68px;
        }

        svg {
          margin-bottom: 10px;
          height: 48px;
          color: $disabled;
          fill: $disabled;
        }
      }

      .subtitle {
        font-size: 16px;
      }
    }
  }
}

.vacuum {
  .video-player {
    height: fit-content;

    iframe {
      width: 100%;
    }
  }
}
