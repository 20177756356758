@import '../../styles/shared.scss';
@import '../../styles/common/mixins.scss';

nav {
  margin-top: 32px;

  @include media(mobile) {
    .current-date {
      display: none;
    }
  }

  .text {
    color: $white;
  }

  .title {
    color: $primary-color;
  }

  .nav-links {
    margin-bottom: 42px;

    @include media(mobile) {
      padding-top: 42px;
    }

    li {
      transition: 0.2s;
      margin-bottom: 32px;

      @include media(desktop) {
        margin-bottom: 24px;
      }

      a {
        display: block;
        width: 100%;
        display: flex;
        align-items: center;
        color: $disabled;
        text-decoration: none;

        .nav-icon {
          margin-right: 16px;
          background-color: $background-2;
          padding: 12px;
          border-radius: $border-radius-element;
          height: 36px;
          width: 36px;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            color: inherit;
            font-size: 16px;
          }

          img {
            filter: opacity(0.3);
            width: 20px;
          }
        }

        .subtitle {
          margin: 0;
          font-size: 15px;
          color: inherit;
        }

        &:hover {
          color: $secondary-color;

          img {
            filter: opacity(1);
          }
        }

        @include media(mobile) {
          margin-left: 32px;

          .nav-icon {
            height: 36px;
            width: 36px;
            padding: 12px;

            img {
              width: 24px;
            }

            svg {
              font-size: 24px;
            }
          }

          .subtitle {
            font-size: 32px;
          }
        }
      }

      &.active {
        .nav-icon {
          background-color: $primary-color;

          svg {
            background-color: $primary-color;
            color: $secondary-color;
          }

          img {
            filter: opacity(1);
          }
        }

        .subtitle {
          color: $secondary-color;
        }
      }
    }
  }
}
