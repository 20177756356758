.new-user {
  max-width: 1200px;
  margin: 0 auto;

  .calories-calculator {
    @include media(desktop) {
      width: 100%;
      margin: 0 auto;
    }

    .background-2 {
      background-color: $background-4;
    }
  }

  .new-user-form {
    @include media(desktop) {
      max-width: 50%;
    }

    .side-radios {
      display: flex;
      justify-content: space-around;

      .input-wrapper {
        width: 40%;
      }
    }

    .content-on-the-right {
      margin-left: auto;
    }

    .field {
      label.main-label {
        font-size: 16px;
        font-family: $avenirBlack;
        padding: 0 0 8px;
        margin: 0;
      }

      .radio-label {
        padding: 10px 0;

        .text {
          font-family: $avenirBook;
        }

        input {
          width: 20px;
          height: 20px;

          &::after {
            width: 22px;
            height: 22px;
            left: -1px;
          }

          &:checked {
            &::after {
              background-size: 10px;
            }
          }
        }
      }
    }
  }
}
