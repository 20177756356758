.lds-heart {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;

  div {
    top: 32px;
    left: 32px;
    position: absolute;
    width: 32px;
    height: 32px;
    background: $primary-color;
    animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);

    &:after,
    &:before {
      content: ' ';
      position: absolute;
      display: block;
      width: 32px;
      height: 32px;
      background: $primary-color;
    }
    &:before {
      left: -24px;
      border-radius: 50% 0 0 50%;
    }
    &:after {
      top: -24px;
      border-radius: 50% 50% 0 0;
    }
  }
}

@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

.dots-spinner-wrapper {
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dots {
  $base-color: $primary-color;

  position: relative;
  left: -9980px;
  top: 14px;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: $base-color;
  color: $base-color;
  box-shadow: 9984px 0 0 0 $base-color, 9999px 0 0 0 $base-color,
    10014px 0 0 0 $base-color;
  animation: dot-typing 1s infinite linear;

  @keyframes dot-typing {
    0% {
      box-shadow: 9984px 0 0 0 $base-color, 9999px 0 0 0 $base-color,
        10014px 0 0 0 $base-color;
    }
    16.667% {
      box-shadow: 9984px -10px 0 0 $base-color, 9999px 0 0 0 $base-color,
        10014px 0 0 0 $base-color;
    }
    33.333% {
      box-shadow: 9984px 0 0 0 $base-color, 9999px 0 0 0 $base-color,
        10014px 0 0 0 $base-color;
    }
    50% {
      box-shadow: 9984px 0 0 0 $base-color, 9999px -10px 0 0 $base-color,
        10014px 0 0 0 $base-color;
    }
    66.667% {
      box-shadow: 9984px 0 0 0 $base-color, 9999px 0 0 0 $base-color,
        10014px 0 0 0 $base-color;
    }
    83.333% {
      box-shadow: 9984px 0 0 0 $base-color, 9999px 0 0 0 $base-color,
        10014px -10px 0 0 $base-color;
    }
    100% {
      box-shadow: 9984px 0 0 0 $base-color, 9999px 0 0 0 $base-color,
        10014px 0 0 0 $base-color;
    }
  }
}

.image-loader {
  color: $white;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    transform-origin: 40px 40px;
    animation: image-loader 1.2s linear infinite;
  }
  div:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 4px;
    height: 16px;
    border-radius: 20%;
    background: #fff;
  }
  div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes image-loader {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
