.marathon-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;

  span {
    color: $grey-decolored;
  }
}

.start-end-date {
  text-transform: uppercase;
  display: flex;

  .text {
    margin-left: 16px;
  }
}

.selector-fix-width {
  min-width: 240px;
  display: inline-block;
}

.days-selector {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 16px;
  column-gap: 12px;

  @include media(desktop) {
    justify-content: flex-start;
    flex-wrap: nowrap;
  }

  .day {
    text-align: center;

    .date {
      font-size: 12px;
      color: $disabled;
      margin-bottom: 4px;
    }

    button {
      width: 64px;
      font-size: 15px;
      font-family: $avenirBlack;
    }
  }
}

.marathon-config-scrollx {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, minmax(164px, 200px));
  grid-template-rows: auto;
  gap: 18px;
  overflow-x: scroll;
  overflow-y: hidden;

  &.no-fix-content {
    display: flex;
    // grid-template-columns: repeat(auto-fill, minmax(220px, 300px));
  }

  &::-webkit-scrollbar {
    height: 6px;
    background-color: $background-2;
    border-radius: $border-radius-element;
  }

  &::-webkit-scrollbar-thumb {
    background: $background-3;
    border-radius: $border-radius-element;
  }

  .single-diet {
    .no-recipe {
      background-color: $background-2;
      padding: 12px;
      border-radius: $border-radius-element;
    }
  }

  .single-workout {
    min-width: 262px;

    .workout-card {
      height: 280px;
      margin-bottom: 24px;

      .play-button {
        display: none;
      }
    }
  }
}

.input-with-trash {
  display: grid;
  grid-template-columns: 85% 1fr;

  &.no-data {
    grid-template-columns: 100%;
  }

  .only-icon {
    align-items: end;
    box-shadow: none;
    padding-bottom: 14px;

    &:hover {
      color: $primary-color;
    }
  }
}

.marathon-tasks {
  background-color: $background-2;
  padding: 24px;
  border-radius: $border-radius-wrapper;

  .single-task {
    margin-bottom: 12px;
  }
}

.user-marathons {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 18px;

  @include media(desktop) {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }

  .marathon-card {
    border-radius: $border-radius-wrapper;
    background-color: $background-2;
    padding: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image-container {
      position: relative;
      img {
        height: 150px;
      }

      .mask {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, transparent, $background-2);
        mask-image: linear-gradient(to bottom, transparent, black);
      }
    }

    button {
      width: 100%;
    }
  }
}

.payment {
  .user-marathons {
    .marathon-card {
      background-color: $background-4;

      .image-container {
        .mask {
          background: linear-gradient(to bottom, transparent, $background-4);
        }
      }
    }
  }
}

.marathon-selector-buttons {
  display: flex;
  justify-content: space-between;

  .simulacion,
  .edit-marathon {
    height: fit-content;
  }
}

@include media(mobile) {
  .marathon-selector-buttons {
    display: flex;
    flex-direction: column-reverse;

    .simulacion {
      margin-bottom: 12px;
    }

    .buttons-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;

      .field {
        width: 100%;
      }
    }
  }
}

.side-dates {
  display: flex;
  column-gap: 14px;

  .input-wrapper {
    width: 100%;
  }

  .field {
    width: 100%;
  }
}
