table {
  background-color: $background-1;
  border-radius: $border-radius-wrapper;
  border-collapse: collapse;
  width: 100%;
  border: none;

  th {
    padding: 12px;
    text-align: left;
    font-family: $avenirBlack;

    button {
      font-family: $avenirBlack;
    }

    svg {
      margin-left: 8px;
    }
  }

  tr > td {
    padding-bottom: 10px;
  }

  tr {
    .button.only-icon {
      svg {
        color: $disabled;
      }

      &:hover {
        svg {
          color: $primary-color;
        }
      }
    }
    &:hover {
      background-color: $background-4;

      button.only-icon {
        svg {
          color: $white;
        }
      }
    }
  }

  td {
    padding: 4px 12px;

    &.currency {
      text-align: right;
    }

    &.state-cell {
      background-color: $white;
      padding: 0;
    }

    &.only-icon {
      text-align: center;
      padding: 0;

      button {
        height: 100%;
        width: 100%;

        &:hover {
          svg {
            cursor: pointer;
            color: $primary-color;
          }
        }
      }

      svg {
        margin: 0 auto;
        color: $secondary-color;
        height: 14px;
      }
    }
  }
}

.data-table {
  .scroll-table {
    width: 100%;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      height: 6px;
      background-color: $background-2;
      border-radius: $border-radius-element;
    }

    &::-webkit-scrollbar-thumb {
      background: $background-3;
      border-radius: $border-radius-element;
    }
  }

  .filters {
    display: flex;
    column-gap: 16px;
    width: 100%;
    margin-bottom: 12px;

    @include media(mobile) {
      flex-direction: column;
      row-gap: 12px;
    }

    .field .multi-select .multi-prefix__input-container {
      height: 36px;
    }
  }

  table {
    border-collapse: collapse;

    th,
    td {
      border: thin solid $background-4;
      padding: 6px 10px;
      text-align: left;

      &.currency {
        text-align: right;
      }

      &.left {
        text-align: left;
      }
    }

    tr {
      &:nth-child(even) {
        background-color: $background-2;
      }

      &:hover {
        background-color: $background-3;
      }

      .only-icon {
        margin: 0 auto;

        svg {
          font-size: 14px;
          margin: 0;
        }
      }

      button {
        margin: 0 auto;
        padding: 8px;
        background-color: $background-4;
      }

      .only-icon {
        padding: 6px;
      }

      td {
        text-align: center;

        &::first-letter {
          text-transform: uppercase;
        }

        &.lowercase {
          text-transform: lowercase;

          &::first-letter {
            text-transform: lowercase;
          }
        }
      }
    }

    th {
      font-family: $avenirBook;
      background-color: $disabled;
      text-align: center;
      font-size: 14px;
      padding: 4px;
      text-transform: uppercase;

      button {
        background-color: $disabled;
        text-transform: uppercase;
        font-size: 14px;
        font-family: $avenirBook;
      }
    }
  }
}
