.user-profile {
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 42px;

  .section {
    background-color: $background-2;
    border-radius: $border-radius-wrapper;
    width: 100%;
    padding: 0 12px 22px;

    @include media(desktop) {
      width: fit-content;
    }

    .subtitle {
      padding: 22px 12px 8px;
      color: $primary-color;
    }
  }
}

.body-fotos-section {
  width: 100%;
  background-color: rgba($color: $black, $alpha: 0.5);
  padding: 24px;
  border-radius: $border-radius-wrapper;
}

.body-fotos {
  display: flex;
  flex-direction: column;

  @include media(desktop) {
    flex-direction: row;
    justify-content: space-around;
    column-gap: 1rem;
  }
}

.body-template {
  margin-bottom: 32px;
  border-radius: $border-radius-element;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  img {
    width: 200px;
  }

  .body-template-image {
    border-radius: $border-radius-element;
    height: 372px;
    width: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .body-foto {
    position: relative;

    .body-siluete {
      position: absolute;
      top: 0;
    }
  }
}
