.resume-table {
  background-color: transparent;
  width: auto;

  tr,
  th,
  tr,
  td {
    text-align: left;
    padding: 0;
  }

  th,
  td {
    padding: 4px 12px;
  }

  tr {
    &.with-bullets {
      position: relative;

      &::before {
        width: 6px;
        height: 6px;
        border-radius: 90px;
        background: #f70386;
        position: absolute;
        top: 9px;
        left: 4px;
        content: '';
      }

      th {
        padding-left: 24px;
      }
    }
  }
}
