form {
  .subtitle,
  label {
    color: $secondary-color;
    font-size: 16px;
    margin: 8px 0 6px 0;
    font-family: $avenirBook;
    margin-bottom: 6px;
    display: block;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.field {
  position: relative;
  width: 100%;

  .input-file p {
    height: 50px;
    display: flex;
    align-items: center;
  }

  &.link {
    label {
      cursor: default;
      text-decoration: none;
    }
  }

  textarea,
  input,
  select,
  .input-file {
    border-radius: $border-radius-element;
    background-color: $background-4;
    padding: 12px 18px;
    font-family: $avenirBook;
    font-size: 16px;
    width: 100%;
    height: 50px;
    box-shadow: $box-shadow-element;
    border: 1px solid #404350;
    cursor: pointer;

    &::placeholder,
    .placeholder {
      color: $disabled;
    }

    &.no-value {
      color: $grey;
    }

    &.has-value {
      color: $secondary-color;
    }

    &.has-error {
      background-color: rgba($color: $primary-color-decolored, $alpha: 0.1);
      border: thin solid $primary-color-decolored;
    }

    &::-webkit-calendar-picker-indicator {
      margin-right: -0.35em;
      filter: invert(48%) sepia(30%) saturate(0%) hue-rotate(203deg)
        brightness(100%) contrast(95%);
      cursor: pointer;
    }

    &[type='number'] {
      text-align: right;
    }

    &[type='date']::-webkit-calendar-picker-indicator {
      background: transparent;
      color: transparent;
      bottom: 0;
      cursor: pointer;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
      height: auto;
    }

    &:disabled {
      color: $disabled;
      cursor: not-allowed;
    }
  }

  textarea {
    min-height: 80px;
  }

  &.checkbox {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    margin-bottom: 12px;

    &.checked {
      label {
        text-decoration: line-through;
      }
    }

    input {
      cursor: pointer;
      padding: 0;
      margin: 0;
      margin-right: 14px;
      border: none;
      -webkit-appearance: none;
      appearance: none;
      width: 22px;
      height: 22px;

      &::after {
        content: '';
        background-color: $grey-input;
        width: 22px;
        height: 22px;
        border-radius: 4px;
        top: 0px;
        left: 0px;
        position: relative;
        display: inline-block;
        visibility: visible;
        border: none;
      }

      &:checked:after {
        content: '';
        background-color: $primary-color;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('../../styles/img/check.png'); // Ruta a tu ícono de tick SVG
        background-position: center;
        background-repeat: no-repeat;
        background-size: 14px;
      }
    }

    label {
      margin: 0;
      color: $secondary-color;
      font-family: $avenirHeavy;
      font-size: 18px;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    &:after {
      top: -5px;
      width: 5px;
      height: 10px;
      left: 2px;
      color: white;
    }
  }

  &.register {
    label {
      font-size: 20px;
      padding-bottom: 22px;
      font-family: $avenirBlack;
    }
  }

  &.radio {
    &:focus {
      background-color: transparent;
      outline: none;
      box-shadow: none;
      outline: 0 !important;
      outline: none !important;
      -webkit-tap-highlight-color: transparent !important;
    }

    label {
      margin: 0;
    }

    .main-label {
      font-size: 20px;
      padding-bottom: 22px;
      font-family: $avenirBlack;
      border-bottom: 1px solid #52566c;
    }

    .radio-label {
      padding: 22px 0;
      border-bottom: 1px solid #52566c;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      input {
        width: 35px;
        height: 35px;
        margin: 0;
        margin-right: 16px;
        box-shadow: none;
        position: relative;

        &::after {
          content: '';
          width: 35px;
          height: 35px;
          border-radius: 100%;
          top: 0px;
          left: 0px;
          position: relative;
          background-color: $grey-input;
          display: inline-block;
          visibility: visible;
          border: none;
        }

        &:checked:after {
          background-color: $primary-color;
          content: '';
          color: $white;
          display: flex;
          justify-content: center;
          align-items: center;
          background-image: url('../../styles/img/check.png'); // Ruta a tu ícono de tick SVG
          background-position: center;
          background-repeat: no-repeat;
          background-size: 14px;
        }
      }

      .text {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        font-size: 16px;

        span {
          font-family: $avenirBook;
        }
      }
    }
  }

  .multi-select {
    color: $grey-dark;
    font-size: 16px;
    z-index: 4;

    input {
      box-shadow: none;
    }

    .multi-prefix__control {
      cursor: pointer;
      background-color: $background-4;
      border: none;
      border-radius: $border-radius-element;
      box-shadow: $box-shadow-element;
      padding-left: 8px;
      border: thin solid #404350;

      .multi-prefix__value-container {
        min-width: 80px;
      }

      .multi-prefix__single-value {
        color: $secondary-color;
        font-family: $avenirBook;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      .multi-prefix__placeholder {
        color: $disabled;
      }
    }

    .multi-prefix__multi-value {
      background-color: $primary-color;
      border-radius: 20px;
      color: $white;
      margin-right: 10px;

      .multi-prefix__multi-value__label {
        color: $white;
      }
    }

    .multi-prefix__input-container {
      margin: 0;
      padding: 0;
      height: 42px;
    }

    .multi-prefix__indicator-separator {
      display: none;
    }

    div[role='button'],
    .multi-prefix__indicator {
      cursor: pointer;
    }

    .multi-prefix__indicator {
      padding-right: 12px;

      svg {
        height: 22px;
        width: 22px;
      }
    }

    .multi-prefix__clear-indicator {
      padding-right: 0;
      position: absolute;
      right: -32px;

      svg {
        color: transparent;
      }
    }

    input::placeholder {
      color: $disabled;
      font-size: 16px;
    }

    svg {
      margin: 0;
    }
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;

  background-image: linear-gradient(45deg, transparent 50%, $grey 50%),
    linear-gradient(135deg, $grey 50%, transparent 50%),
    linear-gradient(to right, $grey, $grey);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;

  &:focus,
  &.has-value {
    background-image: linear-gradient(
        45deg,
        $primary-color 50%,
        transparent 50%
      ),
      linear-gradient(135deg, transparent 50%, $primary-color 50%),
      linear-gradient(to right, $primary-color, $primary-color-decolored);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
      calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    outline: 0;
  }
}

.field {
  position: relative;

  input[type='file'] {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    padding: 36px;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;

    &::-webkit-file-upload-button {
      cursor: pointer;
    }
  }

  .input-file {
    display: flex;
    justify-content: space-between;
    padding: 0;
    overflow: hidden;

    p {
      padding: 8px 18px;
      margin: 0;
    }

    p:last-of-type {
      background-color: $background-4;
      border-radius: 0 $border-radius-element $border-radius-element 0;
      color: $white;
    }
  }
}

.search-input {
  display: flex;
  width: fit-content;
  height: 42px;

  input {
    height: 100%;
    font-weight: bold;
    border-radius: $border-radius-element 0 0 $border-radius-element;
  }

  button {
    z-index: 1;
    border-radius: 0 $border-radius-element $border-radius-element 0;
  }

  p {
    display: none;
  }
}

input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.password-cta {
  position: absolute;
  top: 36px;
  right: 0;
  box-shadow: none;
}

.emojiFlag {
  height: 11px;
}

.input-wrapper {
  display: flex;
  position: relative;

  input {
    width: 100%;
  }

  .trash-input {
    padding: 0 12px;
    border-radius: 0 $border-radius-element $border-radius-element 0;
    background-color: $background-4;
    box-shadow: $box-shadow-element;
    border: 1px solid #404350;
    cursor: pointer;
    margin-left: -6px;
    z-index: 4;

    &.file-type {
      height: 50px;
      align-self: flex-end;
      background-color: $background-5;
    }

    svg {
      font-size: 14px;
    }

    &.active {
      background-color: $primary-color;
    }
  }
}
